import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

interface LabelledItemProps {
  label: string;
  value: string | JSX.Element;
  arrowLink?: string;
  wrapperClasses?: string;
  labelClasses?: string;
  valueClasses?: string;
}
export function LabelledItem({
  label,
  value,
  arrowLink,
  wrapperClasses,
  labelClasses = 'font-nunito mb-[10px] text-sm text-gray-500',
  valueClasses = 'font-nunito break-all font-medium',
}: LabelledItemProps) {
  return (
    <div className={wrapperClasses}>
      <p className={labelClasses}>{label} </p>
      <div className="flex gap-2">
        <div className={valueClasses}>{value}</div>
        {arrowLink && (
          <a href={arrowLink} rel="noreferrer">
            <ArrowTopRightOnSquareIcon className="text-primary-900 h-5 w-5 cursor-pointer" />
          </a>
        )}{' '}
      </div>
    </div>
  );
}

export default LabelledItem;
